import { Fab, Grid, Typography } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PlusIcon from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react';
import { RouteComponentProps } from 'react-router';
import MainContainer from '../../components/MainContainer';
import TimerCard from '../../components/TimerCard/TimerCard';
import TimerEditor from '../../components/TimerEditor/TimerEditor';
import { useSession } from '../../firebase/auth';
import { getTimers } from '../../firebase/event';
import { ITimer } from '../../models/ITimer';

interface MatchProps {
    eventID: string;
}

export interface EventProps extends RouteComponentProps<MatchProps> {}

const useStyles = makeStyles((theme: Theme) => ({
    fab: {
        position: 'fixed',
        bottom: '60px',
        right: '100px',
        zIndex: 1200,
    },
}));

const Event: React.FunctionComponent<EventProps> = props => {
    const {
        match: {
            params: { eventID },
        },
    } = props;
    const classes = useStyles();
    const user = useSession();

    const [timers, setTimers] = useState([] as ITimer[]);

    const [creating, setCreating] = useState(false);

    const [currentTimer, setCurrentTimer] = useState();

    useEffect(() => {
        getTimers(eventID, snapshot => {
            let eventsResult = [] as ITimer[];
            snapshot.docs.forEach(doc => {
                eventsResult.push({ ...doc.data(), id: doc.id } as ITimer);
            });
            setTimers(eventsResult);
        });
    }, [eventID]);

    const editTimer = (timer: ITimer) => () => {
        setCurrentTimer(timer);
        setCreating(true);
    };

    return (
        <MainContainer>
            {user ? (
                <Fab
                    className={classes.fab}
                    variant="extended"
                    aria-label="add"
                    color="primary"
                    onClick={() => setCreating(true)}
                >
                    <PlusIcon />
                    Create Timer
                </Fab>
            ) : null}

            <Grid container={true} spacing={2}>
                {timers.length > 0 ? (
                    timers.map((timer, index) => (
                        <Grid item={true} xs={12} sm={6} lg={3} key={timer.id}>
                            <TimerCard
                                eventID={eventID}
                                timer={timer}
                                handleEdit={editTimer(timer)}
                            />
                        </Grid>
                    ))
                ) : (
                    <Typography>
                        You have no timers yet start creating.
                    </Typography>
                )}
            </Grid>
            <TimerEditor
                eventID={eventID}
                timer={currentTimer}
                open={creating}
                onClose={() => {
                    setCreating(false);
                    setCurrentTimer(undefined);
                }}
            />
        </MainContainer>
    );
};

export default Event;
