import React, { useState } from 'react';
import { IEvent } from '../../models/IEvent';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Typography from '@material-ui/core/Typography';
import emptyTimer from '../../assets/images/empty_timer.png';
import { RouteComponentProps, withRouter } from 'react-router';
import { useSession } from '../../firebase/auth';
import moment from 'moment';
import {deleteEvent} from "../../firebase/event";
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import {IconButton} from "@material-ui/core";

export interface EventCardProps extends RouteComponentProps {
    event: IEvent;
    handleEdit: (event: IEvent) => void;
}

const EventCard: React.FunctionComponent<EventCardProps> = props => {
    const { event, history, handleEdit } = props;
    const [depth, setDepth] = useState(1);
    const user = useSession();
    const toggleDepth = (d: number) => () => setDepth(d);

    const goToEvent = (eventID: string) => () => {
        history.push(`event/${eventID}`);
    };

    return (
        <Card
            elevation={depth}
            onMouseOver={toggleDepth(2)}
            onMouseOut={toggleDepth(1)}
        >
            <CardActionArea onClick={goToEvent(event.id!)}>
                <CardMedia
                    image={event.imageLink ? event.imageLink : emptyTimer}
                    title={event.name}
                    style={{ height: 100 }}
                />
                <CardContent>
                    <Typography gutterBottom={true} variant="h5" component="h2">
                        {event.name}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                    >
                        Created{' '}
                        {event.createdOn &&
                            moment(event.createdOn!.toMillis()).fromNow()}
                    </Typography>
                </CardContent>
            </CardActionArea>
            <CardActions>
                {user ? (
                    <>
                        <IconButton onClick={() => handleEdit(event)}>
                            <EditIcon/>
                        </IconButton>

                        <IconButton
                            onClick={() => {
                                if (
                                    window.confirm(
                                        'Are you sure you wish to delete this event?'
                                    )
                                ) {
                                    deleteEvent(event.id!);
                                }
                            }}
                        >
                            <DeleteIcon/>
                        </IconButton>
                    </>
                ) : null}
            </CardActions>
        </Card>
    );
};

export default withRouter(EventCard);
