import React, {useEffect, useState} from 'react';
import {RouteComponentProps} from 'react-router';
import DbTimer from '../../components/DbTimer';
import './timer.scss';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import {getTimer} from '../../firebase/event';
import {ITimer} from '../../models/ITimer';
import {useTheme} from './theme-selector';
import classNames from 'classnames';
import HamburgerMenu from 'react-hamburger-menu';

interface MatchProps {
    eventID: string;
    timerID: string;
}

export interface TimerProps extends RouteComponentProps<MatchProps> {}

const Timer: React.FunctionComponent<TimerProps> = props => {
    const [fullScreen, setFullScreen] = React.useState(false);
    const [open, setOpen] = React.useState(false);
    const [timer, setTimer] = useState({} as ITimer);
    const [colors, switchTheme] = useTheme();

    const {
        match: {
            params: {eventID, timerID},
        },
    } = props;

    const menuClasses = classNames({
        menu: true,
        'menu-open': open,
        'menu-close': !open,
    });

    const handleChangeTheme = (event: React.ChangeEvent<{ value: unknown }>) => {
        const themeValue = event.target.value as string;
        switchTheme(themeValue);
    };

    const handleClickMenu = () => {
        setOpen(prevState => !prevState);
    };

    const toggleFullScreen = () => {
        if (document.fullscreenElement) {
            document.exitFullscreen().then();
            setFullScreen(false);
        } else {
            document.documentElement.requestFullscreen().then();
            setFullScreen(true);
        }
    };

    useEffect(() => {
        // Set default theme
        switchTheme('light-default');

        return getTimer(eventID, timerID, snapshot => {
            setTimer(snapshot.data() as ITimer);
        });
    }, [eventID, timerID]);

    return (
        <div
            className="timerPage"
            style={{backgroundColor: colors.backgroundColor}}
        >
            <div className="timerPage__header">
                {fullScreen ? (
                    <FullscreenExitIcon
                        className={'icon'}
                        style={{color: colors.textColor}}
                        onClick={toggleFullScreen}
                    />
                ) : (
                    <FullscreenIcon
                        className={'icon'}
                        style={{color: colors.textColor}}
                        onClick={toggleFullScreen}
                    />
                )}
                {(fullScreen && !open) ? (
                    ''
                ) : (
                    <div className="menu-icon">
                        <HamburgerMenu
                            isOpen={open}
                            color={open ? colors.backgroundColor : colors.textColor}
                            menuClicked={handleClickMenu}
                            height={20}
                        />
                    </div>
                )}
            </div>

            <div className="timerPage__body">
                <h2 className="tournament" style={{color: colors.textColor}}>
                    {timer.tournament}
                </h2>
                <DbTimer
                    textBig={true}
                    textColor={colors.textColor}
                    timerID={timerID}
                    eventID={eventID}
                />
            </div>

            <div
                className={menuClasses}
                style={{backgroundColor: colors.textColor}}
            >
                <div className="menu__theme">
                    <label style={{color: colors.backgroundColor}}>Theme:&nbsp;</label>
                    <select
                        onChange={handleChangeTheme}
                        style={{
                            background: colors.backgroundColor,
                            color: colors.textColor,
                        }}
                    >
                        <option value="light-default">
                            Light&nbsp;-&nbsp;Default
                        </option>
                        <option value="light-black">
                            Light&nbsp;-&nbsp;Black
                        </option>
                        <option value="light-red">Light&nbsp;-&nbsp;Red</option>
                        <option value="light-blue">
                            Light&nbsp;-&nbsp;Blue
                        </option>
                        <option value="dark-default">
                            Dark&nbsp;-&nbsp;Default
                        </option>
                        <option value="dark-white">
                            Dark&nbsp;-&nbsp;White
                        </option>
                        <option value="dark-red">Dark&nbsp;-&nbsp;Red</option>
                        <option value="dark-blue">Dark&nbsp;-&nbsp;Blue</option>
                    </select>
                </div>
            </div>
        </div>
    );
};

export default Timer;
