import { ThemeProvider } from '@material-ui/styles';
import React, { useEffect } from 'react';
import {
    Redirect,
    Route,
    RouteComponentProps,
    Switch,
    withRouter,
} from 'react-router-dom';
import { LOGIN, OVERVIEW, OVERVIEW_EVENT, TIMER } from '../constants/routes';
import { useAuth, UserContext } from '../firebase/auth';
import Event from '../pages/Event';
import Events from '../pages/Events';
import Login from '../pages/Login';
import Timer from '../pages/Timer';
import './App.scss';
import theme from './Theme';

const App: React.FC<RouteComponentProps> = props => {
    const { history } = props;
    const { initializing, user } = useAuth();

    useEffect(() => {
        if (!initializing) {
            if (user && history.location.pathname === '/login') {
                history.push('/event');
            }
        }
    }, [user, initializing]);

    if (initializing) {
        return <div>Loading...</div>;
    }

    return (
        <React.Fragment>
            <ThemeProvider theme={theme}>
                <UserContext.Provider value={{ user }}>
                    <Switch>
                        <Redirect exact={true} from="/" to="/event" />
                        <Route
                            exact={true}
                            path={`${TIMER}`}
                            component={Timer}
                        />
                        <Route path={`${OVERVIEW_EVENT}`} component={Event} />
                        <Route path={`${OVERVIEW}`} component={Events} />
                        <Route path={`${LOGIN}`} component={Login} />
                        <Redirect to="/event" />
                    </Switch>
                </UserContext.Provider>
            </ThemeProvider>
        </React.Fragment>
    );
};

export default withRouter(App);
