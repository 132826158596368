import { Grid, IconButton } from '@material-ui/core';
import AppBar from '@material-ui/core/AppBar';
import Container from '@material-ui/core/Container';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Theme } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import useScrollTrigger from '@material-ui/core/useScrollTrigger';
import AccountCircle from '@material-ui/icons/AccountCircle';
import { withStyles } from '@material-ui/styles';
import React, { ReactNode } from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import logo from '../../assets/images/logo.png';
import { useSession } from '../../firebase/auth';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

const WhiteAppBar = withStyles((theme: Theme) => ({
    colorPrimary: {
        backgroundColor: theme.palette.common.white,
    },
}))(AppBar);

const PaddedContainer = withStyles((theme: Theme) => ({
    root: {
        marginTop: theme.spacing(5),
    },
}))(Container);

interface MatchParams {}

export interface MainContainerProps extends RouteComponentProps<MatchParams> {
    children: ReactNode;
}

interface HideOnScrollProps {
    children: React.ReactElement;
}

const HideOnScroll = (props: HideOnScrollProps) => {
    const { children } = props;
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 0,
    });

    return React.cloneElement(children, {
        elevation: trigger ? 4 : 1,
    });
};

const MainContainer: React.FunctionComponent<MainContainerProps> = props => {
    const { children, history } = props;
    const user = useSession();
    return (
        <React.Fragment>
            <CssBaseline />
            <HideOnScroll {...props}>
                <WhiteAppBar>
                    <Toolbar>
                        <Grid
                            container={true}
                            justify="space-between"
                            alignItems="center"
                        >
                            <Grid
                                item={true}
                                xs={2}
                                container={true}
                                justify="flex-start"
                            >
                                <a
                                    href='javascript://'
                                    onClick={() => history.push('/events')}
                                    rel='noopener noreferrer'>
                                    <img
                                        src={logo}
                                        alt="Logo"
                                        style={{
                                            display: 'block',
                                            height: '4vh',
                                        }}
                                    />
                                </a>
                            </Grid>
                            <Grid
                                item={true}
                                xs={8}
                                container={true}
                                justify="center"
                            >
                                <IconButton onClick={() => history.push('/events')}>
                                    Home
                                </IconButton>
                            </Grid>
                            <Grid
                                item={true}
                                xs={2}
                                container={true}
                                justify="flex-end"
                            >
                                <IconButton
                                    aria-label="account of current user"
                                    aria-controls="menu-appbar"
                                    aria-haspopup="true"
                                    color="default"
                                    onClick={() => history.push('/login')}
                                >
                                    {user ? (
                                        <ExitToAppIcon />
                                    ) : (
                                        <AccountCircle />
                                    )}
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Toolbar>
                </WhiteAppBar>
            </HideOnScroll>
            <Toolbar />
            <PaddedContainer maxWidth="md">{children}</PaddedContainer>
        </React.Fragment>
    );
};

export default withRouter(MainContainer);
