import { auth } from './firebase';
import React from 'react';
// Sign In
export const doSignInWithEmailAndPassword = (email: string, password: string) =>
    auth.signInWithEmailAndPassword(email, password);

export const signOut = () => {
    auth.signOut();
};

type ContextProps = {
    user: any;
};

export const UserContext = React.createContext<Partial<ContextProps>>({
    user: null,
});

export const useSession = () => {
    const { user } = React.useContext(UserContext);
    return user;
};

export const useAuth = () => {
    const [state, setState] = React.useState(() => {
        const user = auth.currentUser;
        return { initializing: !user, user };
    });
    function onChange(user: any) {
        setState({ initializing: false, user });
    }

    React.useEffect(() => {
        // listen for auth state changes
        const unsubscribe = auth.onAuthStateChanged(onChange);
        // unsubscribe to the listener when unmounting
        return () => unsubscribe();
    }, []);

    return state;
};
