import {useState} from 'react';
import {black, blue, green, ITheme, red, white} from './ITheme';

export const useTheme = (): [ITheme, (v:string)=>void] => {
    const [colors, setColors] = useState({} as ITheme);

    const switchTheme = (theme: string) => {
        switch (theme) {
            case 'light-default': {
                setColors({backgroundColor: white, textColor: green});
                break;
            }
            case 'light-black': {
                setColors({backgroundColor: white, textColor: black});
                break;
            }
            case 'light-red': {
                setColors({backgroundColor: white, textColor: red});
                break;
            }
            case 'light-blue': {
                setColors({backgroundColor: white, textColor: blue});
                break;
            }
            case 'dark-default': {
                setColors({backgroundColor: black, textColor: green});
                break;
            }
            case 'dark-white': {
                setColors({backgroundColor: black, textColor: white});
                break;
            }
            case 'dark-red': {
                setColors({backgroundColor: black, textColor: red});
                break;
            }
            case 'dark-blue': {
                setColors({backgroundColor: black, textColor: blue});
                break;
            }
        }
    };

    return [colors, switchTheme];
};
