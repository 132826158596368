import { FormControlLabel, Grid, Switch, TextField } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import React, {
    useEffect,
    useState,
    ChangeEvent,
} from 'react';
import { ITimer, displayNoText, toMillis } from '../../models/ITimer';
import CreateDialog from '../CreateDialog';
import { CreateDialogProps } from '../CreateDialog/CreateDialog';
import {createOrUpdateTimer} from "../../firebase/event";

const useStyles = makeStyles((theme: Theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dialog: {
        minWidth: '30vw',
        minHeight: '40vh',
    },
}));

export interface TimerEditorProps {
    open: boolean;
    onClose: CreateDialogProps['onChange'];
    eventID: string;
    timer?: ITimer;
}

const initial = { tournament: '', duration: 0, stopAtZero: false } as ITimer;

const TimerEditor: React.FunctionComponent<TimerEditorProps> = props => {
    const { open, onClose = (event: any) => {}, eventID, timer } = props;
    const classes = useStyles();
    const [form, setForm] = useState(timer || initial);
    const [loading, setLoading] = useState(false);
    const [duration, setDuration] = useState(displayNoText(form.duration || 0));

    useEffect(() => {
        if (timer !== undefined) {
            setForm(timer);
            setDuration(displayNoText(timer.duration || 0));
        } else {
            setForm(initial);
            setDuration('000000');
        }
    }, [timer]);

    const handleUpdateTimer = (
        field: keyof ITimer,
        mapType: (type: string) => any = type => type
    ) => (event: any) => {
        const value = event.target.value;
        setForm(form => ({ ...form, [field]: mapType(value) }));
    };

    const handleSubmit = async (event: any) => {
        setLoading(true);
        form.duration = toMillis(duration);
        await createOrUpdateTimer(
            eventID,
            {
                ...form!,
                actualDuration: form.duration,
            }
        );

        setLoading(false);
        setForm(initial);
        onClose(event);
    };

    const handleChangeDuration = (event: ChangeEvent<HTMLInputElement>) => {
        if (event && event.target) {
            const value = event.target.value;
            if (value.length < 12) {
                setDuration(duration => '0'.concat(duration).substr(0, 6));
            } else {
                const inputChar = value.substr(11, 1);
                if (!isNaN(Number(inputChar))) {
                    setDuration(duration =>
                        duration.concat(inputChar).substr(1, 6)
                    );
                }
            }
        }
    };
    const durationArray = duration.match(/.{1,2}/g);
    const displayDuration = durationArray
        ? `${durationArray[0]}h ${durationArray[1]}m ${durationArray[2]}s`
        : '';
    return (
        <CreateDialog
            title={
                timer && timer.tournament !== ''
                    ? `Update ${timer.tournament}`
                    : 'Create Timer'
            }
            open={open}
            handleClose={onClose}
            onSubmit={handleSubmit}
            className={classes.dialog}
            loading={loading}
        >
            <Grid container={true} spacing={2} alignItems="stretch">
                <Grid item={true} xs={12}>
                    <TextField
                        required={true}
                        id="outlined-required"
                        label="Duration"
                        placeholder={displayDuration}
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        fullWidth={true}
                        value={displayDuration}
                        onChange={handleChangeDuration}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        required={true}
                        id="outlined-required"
                        label="Tournament"
                        placeholder="Tournament"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        fullWidth={true}
                        value={form.tournament}
                        onChange={handleUpdateTimer('tournament')}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <FormControlLabel
                        labelPlacement="start"
                        control={
                            <Switch
                                checked={!form.stopAtZero || initial.stopAtZero}
                                onChange={handleUpdateTimer(
                                    'stopAtZero',
                                    value => !form.stopAtZero
                                )}
                                value={!form.stopAtZero}
                                color="primary"
                            />
                        }
                        label="Continue after finishing?"
                    />
                </Grid>
            </Grid>
        </CreateDialog>
    );
};

export default TimerEditor;
