import firebase from 'firebase';
import moment from 'moment';
import {createOrUpdateTimer} from "../firebase/event";
import { functions } from '../firebase/firebase';


export interface ITimer {
    id?: string;
    createdOn?: Date & firebase.firestore.Timestamp;
    stoppedOn?: Date & firebase.firestore.Timestamp;
    tournament: string;
    duration?: number; // milliseconds
    actualDuration?: number;
    operationTime?: Date & firebase.firestore.Timestamp;
    stopAtZero?: boolean;
    fontColor?: string;
    backgroundColor?: string;
    running?: boolean;
}


export const display = (milliseconds: number) => {
    const absMilliseconds = Math.abs(milliseconds);
    if(absMilliseconds >= 3600 * 1000) {
        return moment.utc(absMilliseconds).format(`HH:mm:ss`);
    }else if(absMilliseconds < 3600*1000 && absMilliseconds >= 60 *1000) {
        return moment.utc(absMilliseconds).format(`mm:ss`);
    }else{
        return moment.utc(absMilliseconds).format(`ss`);
    }
};

export const displayNoText = (milliseconds: number) => {
    return moment.utc(Math.abs(milliseconds)).format(`HHmmss`);
};

export const toMillis = (format: string) => {
    const durationArray = format.match(/.{1,2}/g);
    if (durationArray) {
        return moment
            .utc(0)
            .add(durationArray[2], 'seconds')
            .add(durationArray[1], 'minutes')
            .add(durationArray[0], 'hours')
            .valueOf();
    }
};

export const toMillisecondsLeft = (timer: ITimer, eventID: string): number => {
    if (timer.running && serverOffset) {
        //console.log(`Timer is running`);
        //console.log(timer);
        if (timer.operationTime && timer.actualDuration) {
            const doneMs = timer.operationTime.toMillis();
            const remainingTime = timer.actualDuration - (getCurrentServerTime().getTime() - doneMs);
            //console.log(`actualDuration: ${timer.actualDuration}, now: ${getCurrentServerTime()}, lastChange: ${timer.operationTime.toDate()}, remainingTime: ${remainingTime}`);
            if (remainingTime <= 0 && timer.stopAtZero) {
                //console.log(`Stopping timer`);
                // Stop Timer
                timer.running = false;
                createOrUpdateTimer(
                    eventID,
                    {
                        ...timer,
                        running: false,
                        actualDuration: 0,
                    }
                );
                return 0;
            } else {
                //console.log(`Returning timer ${remainingTime}`);
                // Timer running
                return remainingTime;
            }
        }
    }else {
        //console.log('Timer not running');
        //console.log(timer);
    }
    return timer.actualDuration || 0;
};

// Returns the difference in ms between current time and server time
function getServerOffset(): Promise<number> {
    return new Promise<number>(function(resolve) {
        const getServerTime = functions.httpsCallable('getServerTime');
        getServerTime().then(function(result) {
            // Parse server time
            const serverTime = moment(result.data.currentTime);
            const now = moment();
            console.log(`Current time: ${now}`);
            console.log(`Server time: ${serverTime}`);
            console.log(`Difference: ${serverTime.diff(now)}`);
            resolve(serverTime.diff(now));
        });
    });
}

export let serverOffset:number; getServerOffset().then(value => serverOffset = value );

export function getCurrentServerTime(): Date {
    return new Date(Date.now()+(serverOffset||0));
}
export function dateToServerTime(dateToCorrect:Date): Date {
    return new Date(dateToCorrect.getTime()+(serverOffset||0));
}
