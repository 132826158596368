import { useEffect, useRef } from 'react';

type IFunction = () => void;

function useInterval(callback: IFunction, delay: number | null) {
    const savedCallback = useRef<IFunction>(() => undefined);

    // Remember the latest callback
    useEffect(() => {
        savedCallback.current = callback;
    }, [callback]);

    // Set up the interval
    useEffect(() => {
        let tick = () => {
            savedCallback.current();
        };
        if (delay !== null) {
            let id = setInterval(tick, delay);
            return () => clearInterval(id);
        }
    }, [delay]);
}

export default useInterval;
