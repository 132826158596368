import {Grid, IconButton} from '@material-ui/core';
import Card from '@material-ui/core/Card';
import CardActionArea from '@material-ui/core/CardActionArea';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Typography from '@material-ui/core/Typography';
import PauseIcon from '@material-ui/icons/Pause';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import RotateLeftIcon from '@material-ui/icons/RotateLeft';
import React, {useState} from 'react';
import {useHistory} from 'react-router';
import {useSession} from '../../firebase/auth';
import {createOrUpdateTimer, deleteTimer} from '../../firebase/event';
import {display, ITimer, toMillisecondsLeft,} from '../../models/ITimer';
import moment from 'moment';
import DbTimer from '../DbTimer';

export interface TimerCardProps {
    eventID: string;
    timer: ITimer;
    handleEdit: () => void;
}

const TimerCard: React.FunctionComponent<TimerCardProps> = props => {
    const {eventID, timer, handleEdit} = props;
    const [depth, setDepth] = useState(1);
    const toggleDepth = (d: number) => () => setDepth(d);
    const user = useSession();

    const history = useHistory();

    /*
     <Box width={1}>
        <TimerDisplay
            handleCurrentTime={t => setTime(t)}
            milliseconds={time || 0}
            running={timer.running}
            stopAtZero={timer.stopAtZero}
        />
    </Box>
    <hr style = {{
            marginTop: "15px",
            marginBottom: "15px",
            border: "solid 1px #BDBDBD"
        }}
    />
     */
    return (
        <Card
            elevation={depth}
            onMouseEnter={toggleDepth(3)}
            onMouseLeave={toggleDepth(1)}
        >
            <CardActionArea
                onClick={() => {
                    history.push(`/event/${eventID}/timer/${timer.id}`);
                }}
            >
                <CardContent>
                    <DbTimer
                        textBig={false}
                        textColor="black"
                        timerID={timer.id || ''}
                        eventID={eventID}
                    />
                    <hr style={{
                        marginTop: '15px',
                        marginBottom: '15px',
                        border: 'solid 1px #BDBDBD'
                    }}
                    />
                    <Typography gutterBottom={true} variant="h5" component="h2">
                        {timer.tournament}
                    </Typography>
                    <Typography
                        variant="body2"
                        color="textSecondary"
                        component="p"
                    >
                        Created:{' '}
                        {timer.createdOn &&
                        moment(timer.createdOn!.toMillis()).fromNow()}
                    </Typography>

                    <Typography variant="body2">
                        <Typography
                            variant="body2"
                            color="textSecondary"
                            component="span"
                        >
                            Original:
                        </Typography>{' '}
                        {timer.duration && display(timer.duration)}
                    </Typography>
                </CardContent>
            </CardActionArea>
            {user ? (
                <CardActions>
                    <Grid
                        container={true}
                        justify="space-between"
                        alignItems="center"
                    >
                        <Grid item={true}>
                            <IconButton
                                onClick={() =>
                                    createOrUpdateTimer(
                                        eventID,
                                        {
                                            ...timer,
                                            running: !timer.running,
                                            actualDuration: toMillisecondsLeft(timer, eventID),
                                        },
                                        true
                                    )
                                }
                                disabled={timer.actualDuration === 0}
                            >
                                {timer.running ? (
                                    <PauseIcon/>
                                ) : (
                                    <PlayArrowIcon/>
                                )}
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    if (
                                        window.confirm(
                                            'Are you sure you wish to reset the timer?'
                                        )
                                    ) {
                                        createOrUpdateTimer(
                                            eventID,
                                            {
                                                ...timer,
                                                actualDuration: timer.duration,
                                                running: false,
                                            },
                                            true
                                        );
                                    }
                                }}
                                disabled={
                                    timer.running ||
                                    timer.actualDuration === timer.duration
                                }
                            >
                                <RotateLeftIcon/>
                            </IconButton>
                        </Grid>
                        <Grid item={true}>
                            <IconButton
                                onClick={() => {
                                    createOrUpdateTimer(eventID, {
                                        ...timer,
                                        running: false,
                                        actualDuration: toMillisecondsLeft(timer, eventID),
                                    });
                                    handleEdit();
                                }}
                                disabled={timer.running}
                            >
                                <EditIcon/>
                            </IconButton>
                            <IconButton
                                onClick={() => {
                                    if (
                                        window.confirm(
                                            'Are you sure you wish to delete this timer?'
                                        )
                                    ) {
                                        deleteTimer(eventID, timer.id || '');
                                    }
                                }}
                            >
                                <DeleteIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                </CardActions>
            ) : null}
        </Card>
    );
};

export default TimerCard;
