import React, {useEffect, useState} from 'react';
import {getTimer} from '../../firebase/event';
import {ITimer, serverOffset, toMillisecondsLeft} from '../../models/ITimer';
import TimerDisplay from '../TimerDisplay';

export interface DbTimerProps {
    eventID: string;
    timerID: string;
    textColor: string;
    textBig: boolean;
}

const DbTimer: React.FunctionComponent<DbTimerProps> = props => {
    const {eventID, timerID, textColor, textBig} = props;

    const [timer, setTimer] = useState({} as ITimer);
    const [time, setTime] = useState(toMillisecondsLeft(timer, eventID));

    useEffect(() => {
        return getTimer(eventID, timerID, snapshot => {
            setTimer(snapshot.data() as ITimer);
        });
    }, [eventID, timerID]);

    useEffect(() => {
        setTime(toMillisecondsLeft(timer, eventID));
    }, [timer, serverOffset]);

    return (
        <TimerDisplay
            textBig={textBig}
            textColor={textColor}
            handleCurrentTime={t => setTime(t)}
            milliseconds={time || 0}
            running={timer.running}
            stopAtZero={timer.stopAtZero}
        />
    );
};

export default DbTimer;
