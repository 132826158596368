// See colors.scss in assets/styles
export const white = '#fff';
export const blue = '#125281';
export const black = '#393835';
export const gray = '#706f6d';
export const green = 'green';
export const red = 'red';

export interface ITheme {
    backgroundColor: string;
    textColor: string;
}
