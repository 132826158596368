import { Grid, Paper, Typography } from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';
import PersonIcon from '@material-ui/icons/Person';
import React, { useState } from 'react';
import logo from '../../assets/images/logo.png';
import {
    doSignInWithEmailAndPassword,
    useSession,
    signOut,
} from '../../firebase/auth';
import './login.scss';
import { RouteComponentProps } from 'react-router';

export interface LoginProps extends RouteComponentProps<any> {}

const Login: React.FunctionComponent<LoginProps> = props => {
    const { history } = props;
    const [form, setForm] = useState({ email: '', password: '', error: null });
    const user = useSession();

    const handleInputChange = (event: any) => {
        const target = event.currentTarget;
        setForm(oldForm => ({
            ...oldForm,
            [target.name]: target.value,
        }));
    };

    const login = async () => {
        try {
            await doSignInWithEmailAndPassword(form.email, form.password);
            history.push('/event');
        } catch (error) {
            alert(error.message);
        }
    };

    const logout = async () => {
        try {
            await signOut();
            history.push('/event');
        } catch (error) {
            alert(error.message);
        }
    };

    const renderUser = (user: any) => {
        return (
            <>
                <br />
                <Typography variant="body2" component="p">
                    You are logged in with: <strong>{user.email}</strong>
                </Typography>
                <button
                    className="Login__button"
                    type="submit"
                    onClick={logout}
                >
                    Logout
                </button>
            </>
        );
    };

    const renderLogin = () => {
        return (
            <>
                <div className="Login__row">
                    <div className="Login__row__icon">
                        <PersonIcon className="icon" />
                    </div>

                    <input
                        name="email"
                        type="text"
                        required={true}
                        placeholder="Please enter email"
                        onChange={handleInputChange}
                        value={form.email}
                        className="Login__input"
                    />
                </div>

                <div className="Login__row">
                    <div className="Login__row__icon">
                        <LockIcon className="icon" />
                    </div>

                    <input
                        name="password"
                        type="password"
                        required={true}
                        placeholder="Please enter password"
                        onChange={handleInputChange}
                        value={form.password}
                        className="Login__input"
                    />
                </div>

                <button className="Login__button" type="submit" onClick={login}>
                    Login
                </button>
            </>
        );
    };

    return (
        <Grid
            container={true}
            justify="center"
            alignItems="center"
            style={{ paddingTop: '4rem' }}
        >
            <Grid item={true}>
                <Paper elevation={2}>
                    <div className="Login">
                        <img
                            src={logo}
                            alt="logo tournamentcenter"
                            className="Login__logo"
                        />
                        {user ? renderUser(user) : renderLogin()}
                    </div>
                </Paper>
            </Grid>
        </Grid>
    );
};

export default Login;
