import { IEvent } from '../models/IEvent';
import { db } from './firebase';
import * as firebase from 'firebase/app';
import { ITimer } from '../models/ITimer';
import uuid from 'uuid/v1';

const eventCollection = db.collection('events');

const timersCollection = (event: string) =>
    db.collection(`events/${event}/timers`);

type DocumentCallback = (snapshot: firebase.firestore.DocumentSnapshot) => void;
type QueryCallback = (snapshot: firebase.firestore.QuerySnapshot) => void;

const allEvents = (snapShotCallback: QueryCallback) => {
    return eventCollection
        .orderBy('createdOn', 'desc')
        .onSnapshot(snapShotCallback);
};

const getTimers = (eventID: string, snapShotCallback: QueryCallback) => {
    return timersCollection(eventID)
        .orderBy('createdOn', 'desc')
        .onSnapshot(snapShotCallback);
};

const getTimer = (
    eventID: string,
    timerID: string,
    snapShotCallBack: DocumentCallback
) => {
    return timersCollection(eventID)
        .doc(timerID)
        .onSnapshot(snapShotCallBack);
};

const createEvent = (event: IEvent) => {
    eventCollection.add({ ...event, createdOn: firebase.firestore.FieldValue.serverTimestamp() });
};

const createOrUpdateEvent = async (event: IEvent) => {
    try {
        if (event.id) {
            await eventCollection.doc(event.id).update({ ...event });
            return { ...event };
        } else {
            return await eventCollection.add({
                ...event,
                createdOn: new Date(),
            });
        }
    } catch (e) {
        console.error('Error updating event', e);
    }
};

const deleteTimer = async (eventID: string, timerID: string) => {
    try {
        await timersCollection(eventID)
            .doc(timerID)
            .delete();
    } catch (e) {
        console.error('Cannot delete timer', e);
    }
};

const deleteEvent = async (eventID: string) => {
    try {
        await eventCollection.doc(eventID).delete();
    } catch (e) {
        console.log('Cannot delete event', e);
    }
};

//timestamp: firebase.firestore.FieldValue.serverTimestamp()

const createOrUpdateTimer = async (
    eventID: string,
    timer: ITimer,
    resetOperation: boolean = false
) => {
    try {
        if (timer.id) {
            await timersCollection(eventID)
                .doc(timer.id)
                .update({
                    ...timer,
                    ...(resetOperation
                        ? {
                              operationTime: firebase.firestore.FieldValue.serverTimestamp(),
                          }
                        : {}),
                });
        } else {
            const id = uuid();
            await timersCollection(eventID).add({
                ...timer,
                id,
                actualDuration: timer.duration,
                running: false,
                createdOn: new Date(),
                ...(resetOperation
                    ? {
                          operationTime: firebase.firestore.FieldValue.serverTimestamp(),
                      }
                    : {}),
            });
        }
    } catch (e) {
        console.error(e);
    }
};

export {
    getTimer,
    deleteTimer,
    deleteEvent,
    getTimers,
    allEvents,
    createEvent,
    createOrUpdateEvent,
    createOrUpdateTimer,
};
