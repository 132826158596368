import React, {useEffect, useState} from 'react';
import updateInterval from '../../constants/config';
import useInterval from './useInterval';
import {display} from '../../models/ITimer';
import './timerDisplay.scss';

export interface IProps {
    textColor: string;
    handleCurrentTime: (currentTime: number) => void;
    milliseconds: number;
    running?: boolean;
    stopAtZero?: boolean;
    titleStyle?: any;
    textBig: boolean;
}

export default function TimerDisplay(props: IProps) {
    const {
        milliseconds,
        running = true,
        handleCurrentTime,
        stopAtZero = true,
        textColor,
        textBig
    } = props;
    const [time, setTime] = useState(milliseconds);
    const [isRunning, setIsRunning] = useState(running);

    const safeSetTime = (milliseconds: number) => {
        if (milliseconds > 0 || (milliseconds <= 0 && !stopAtZero)) {
            setTime(milliseconds);
        } else {
            setTime(0);
            setIsRunning(false);
        }
    };

    useEffect(() => {
        handleCurrentTime(time);
    }, [time]);

    useEffect(() => {
        safeSetTime(milliseconds);
        setIsRunning(running);
    }, [milliseconds, running]);

    useInterval(
        () => {
            safeSetTime(time - updateInterval);
        },
        isRunning ? updateInterval : null
    );

    return (
        <div className={textBig ? 'big' : 'small'} style={{"color": textColor}}>
            {time < 0 ? '- ' : ''}
            {display(time)}
        </div>
    );
}
