import { CircularProgress } from '@material-ui/core';
import Button from '@material-ui/core/Button';
import Dialog, { DialogProps } from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import { onKeyPressed } from '../../utlis/keyboard';

export interface CreateDialogProps extends DialogProps {
    handleClose?: (event: any) => void;
    onSubmit?: (event: any) => void;
    children: React.ReactElement;
    title: string;
    loading: boolean;
}

const CreateDialog: React.FunctionComponent<CreateDialogProps> = props => {
    const {
        open,
        handleClose,
        onSubmit,
        children,
        title,
        className,
        loading,
    } = props;
    const theme = useTheme();
    const fullScreen = useMediaQuery(theme.breakpoints.down('xs'));

    const handleSubmit = (event: any) => {
        onSubmit && onSubmit(event);
    };

    const submitDisable =
        children.props.children[1].props.children.props.value === undefined ||
        children.props.children[1].props.children.props.value === '';

    return (
        <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="form-dialog-title"
            fullScreen={fullScreen}
            maxWidth="md"
            onKeyUp={onKeyPressed('Enter', handleSubmit)}
        >
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent className={className}>
                <LoadingOverlay active={loading} spinner={<CircularProgress />}>
                    {children}
                </LoadingOverlay>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary" disabled={submitDisable}>
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default CreateDialog;
