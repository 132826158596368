import {storage} from './firebase';

const imageStorage = storage.ref('cover-images/');

const uploadImage = async (name: string, file: File) => {
    const imageRef = imageStorage.child(name);
    const metadata = {
        contentType: file.type,
    };
    return imageRef.put(file, metadata);
};

export { uploadImage };
