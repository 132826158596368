import { Grid, TextField } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import FilePondPluginImageExifOrientation from 'filepond-plugin-image-exif-orientation';
import FilePondPluginImagePreview from 'filepond-plugin-image-preview';
import 'filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css';
import 'filepond/dist/filepond.min.css';
import React, { useState, useEffect } from 'react';
// Import React FilePond
import { File, FilePond, registerPlugin } from 'react-filepond';
import * as Events from '../../firebase/event';
import * as FirebaseImage from '../../firebase/images';
import { isEmpty } from '../../utlis/strings';
import CreateDialog from '../CreateDialog';
import { CreateDialogProps } from '../CreateDialog/CreateDialog';
import { IEvent } from '../../models/IEvent';

registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const useStyles = makeStyles((theme: Theme) => ({
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dialog: {
        minWidth: '30vw',
        minHeight: '40vh',
    },
    fileUploader: {
        height: '30vh',
    },
}));

export interface EventEditorProps {
    event?: IEvent;
    open: boolean;
    onClose: CreateDialogProps['onChange'];
}

const EventEditor: React.FunctionComponent<EventEditorProps> = props => {
    const { open, onClose = (event: any) => {}, event } = props;
    const classes = useStyles();
    const [files, setFiles] = useState(new Array<File>());

    const [form, setForm] = useState<IEvent>({ name: '' } as IEvent);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (event !== undefined) {
            setForm(event);
        } else {
            setForm({ name: '' } as IEvent);
            setFiles([]);
        }
    }, [event]);

    const handleUpdateEvent = (
        field: keyof IEvent,
        mapType: (type: string) => any = type => type
    ) => (event: any) => {
        const value = event.target.value;
        setForm(form => ({ ...form, [field]: mapType(value) }));
    };

    const handleSubmit = async (event: any) => {
        if (!isEmpty(form.name)) {
            try {
                setLoading(true);

                const event = await Events.createOrUpdateEvent({
                    ...form,
                });
                if (event && event.id) {
                    // Upload Image

                    let imageLink = event.id;
                    if (files.length > 0) {
                        try {
                            const image = files[0];
                            const uploaded = await FirebaseImage.uploadImage(
                                imageLink,
                                image.file
                            );
                            imageLink = await uploaded.ref.getDownloadURL();
                            await Events.createOrUpdateEvent({
                                id: event.id,
                                imageLink,
                            } as IEvent);
                        } catch (e) {
                            console.error(e);
                        }
                    }
                }

                setLoading(false);
            } catch (e) {
                console.log(e);
            }
            onClose(event);
        }
    };

    return (
        <CreateDialog
            title={
                event && event.name !== undefined
                    ? `Update ${event.name}`
                    : 'Create Event'
            }
            open={open}
            handleClose={onClose}
            onSubmit={handleSubmit}
            className={classes.dialog}
            loading={loading}
        >
            <Grid container={true} spacing={2} alignItems="stretch">
                <Grid item={true} xs={12}>
                    <FilePond
                        name="Something"
                        files={files}
                        allowMultiple={false}
                        maxFiles={1}
                        onupdatefiles={fileItems => {
                            // Set current file objects to this.state
                            setFiles([...fileItems]);
                        }}
                        className={classes.fileUploader}
                    />
                </Grid>
                <Grid item={true} xs={12}>
                    <TextField
                        required={true}
                        id="outlined-required"
                        label="Event Name"
                        placeholder="Event Name"
                        className={classes.textField}
                        margin="normal"
                        variant="outlined"
                        fullWidth={true}
                        value={form.name}
                        onChange={handleUpdateEvent('name')}
                    />
                </Grid>
            </Grid>
        </CreateDialog>
    );
};

export default EventEditor;
