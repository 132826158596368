import { Fab, Grid } from '@material-ui/core';
import { Theme } from '@material-ui/core/styles';
import makeStyles from '@material-ui/core/styles/makeStyles';
import PlusIcon from '@material-ui/icons/Add';
import React, { useEffect, useState } from 'react';
import EventCard from '../../components/EventCard';
import EventEditor from '../../components/EventEditor';
import MainContainer from '../../components/MainContainer';
import { useSession } from '../../firebase/auth';
import { allEvents } from '../../firebase/event';
import { IEvent } from '../../models/IEvent';

export interface EventsProps {}

const useStyles = makeStyles((theme: Theme) => ({
    fab: {
        position: 'fixed',
        bottom: '60px',
        right: '100px',
        zIndex: 1200,
    },
}));

const Events: React.FunctionComponent<EventsProps> = props => {
    const classes = useStyles();
    const user = useSession();
    const [events, setEvents] = useState([] as IEvent[]);
    const [event, setEvent] = useState<IEvent | undefined>({} as IEvent);

    const [creating, setCreating] = useState(false);

    useEffect(() => {
        return allEvents(snapshot => {
            let eventsResult = [] as IEvent[];
            snapshot.docs.forEach(doc => {
                eventsResult.push({ ...doc.data(), id: doc.id } as IEvent);
            });
            setEvents(eventsResult);
        });
    }, []);

    return (
        <MainContainer>
            {user ? (
                <Fab
                    className={classes.fab}
                    variant="extended"
                    aria-label="add"
                    color="primary"
                    onClick={() => setCreating(true)}
                >
                    <PlusIcon />
                    Create Event
                </Fab>
            ) : null}

            <Grid container={true} spacing={2}>
                {events.map(event => (
                    <Grid item={true} xs={12} sm={6} lg={3} key={event.id}>
                        <EventCard
                            event={event}
                            handleEdit={event => {
                                setEvent(event);
                                setCreating(true);
                            }}
                        />
                    </Grid>
                ))}
            </Grid>
            <EventEditor
                event={event}
                open={creating}
                onClose={() => {
                    setCreating(false);
                    setEvent(undefined);
                }}
            />
        </MainContainer>
    );
};

export default Events;
